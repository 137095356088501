/* eslint-disable react/jsx-no-bind */
import clsx from 'clsx'
import { ReactElement, forwardRef, Ref, useEffect, useState, Fragment } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { useLoadMore } from 'shared-components/hooks/use-load-more'
import VisibleMarker from 'shared-components/service/VisibleMarker'
import dots from 'shared-components/styles/animation-dots.module.css'
import BasicButton from 'shared-components/buttons/BasicButton'
import { BasicStyledComponent, DSPaginatedList } from 'shared-definitions/types'
import styles from './LoadMoreList.module.css'

interface LoadMoreListProps<T> extends BasicStyledComponent {
  loader: (page: number, perPage: number) => Promise<T[]>
  render: (item: T, index: number) => React.ReactNode
  onLoad?: (page: number) => void
  initial: DSPaginatedList<T>
  buttonClassName?: string
  autoload?: boolean | number
  hideButton?: boolean
}

const LoadMoreList = <T,>(
  {
    loader,
    render,
    onLoad,
    initial,
    autoload,
    className,
    buttonClassName,
    hideButton,
  }: LoadMoreListProps<T>,
  ref: Ref<HTMLDivElement>
): React.ReactElement => {
  const {
    common: { strings },
  } = CommonDataContext.useContainer()
  const { currentPage, posts, loading, complete, loadMore } = useLoadMore(initial, loader)
  const [autoloadRemaining, setAutoloadRemaining] = useState(
    // eslint-disable-next-line no-nested-ternary
    typeof autoload === 'number' ? autoload : autoload ? 1 : 0
  )

  useEffect(() => {
    if (!loading && onLoad) {
      onLoad(currentPage)
    }
  }, [currentPage, onLoad, loading])

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      {posts.map((item, index) => (
        <Fragment key={index}>{render(item, index)}</Fragment>
      ))}
      {!hideButton && !loading && !complete ? (
        <BasicButton onClick={loadMore} className={clsx(buttonClassName, styles.button)}>
          {strings.loadMore}
        </BasicButton>
      ) : null}
      {!hideButton && loading ? (
        <BasicButton
          className={clsx(dots.animated, styles.loading, buttonClassName, styles.button)}
        >
          {strings.loading}
        </BasicButton>
      ) : null}
      <VisibleMarker
        disabled={autoloadRemaining <= 0 || complete || loading}
        onVisible={() => {
          if (!complete) {
            setAutoloadRemaining(s => s - 1)
            loadMore()
          }
        }}
      />
    </div>
  )
}

export default forwardRef(LoadMoreList) as <T>(
  p: LoadMoreListProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReactElement
